<template>
  <div class="app__preloader">
    <img
      class="app__preloader__image"
      src="../../assets/icons/purple-logo-transparent.png"
      alt=""
    />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.app__preloader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &__image {
    height: 400px;
    width: 400px;
    animation: pulsar 2s linear infinite;
  }
}

@keyframes pulsar {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
