import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import Notifications from "@kyvg/vue3-notification";

const Application = createApp(App);

Application.use(store);
Application.use(router);
Application.use(Notifications);

Application.mount("#app");
