<template>
  <div class="app-wrapper">
    <!-- <v-login v-if="!isAuthorized" @isAuthorized="setAutorizeStatus" /> -->
    <!-- <home-page v-if="isAuthorized" /> -->
    <home-page />
    <notifications position="bottom right" />
  </div>
</template>

<script>
import homePage from "./components/content/home/homePage.vue";
// import VLogin from "./components/content/login/VLogin.vue";

export default {
  // components: { homePage, VLogin },
  components: { homePage },
  data() {
    return {
      isAuthorized: false,
      isValid: true,
      email: "admin",
      password: "admin",
      token: null,
    };
  },
  methods: {
    setAutorizeStatus(value) {
      this.isAuthorized = value;
    },
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "setToken":
          if (state.token) {
            this.isAuthorized = true;
          }
          break;
      }
    });
  },
};
</script>

<style lang="scss">
#app {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.app-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
