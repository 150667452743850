import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

export function RestGateway(config) {
  const response = axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const status = error.response.status;
      let message = "";

      switch (status) {
        case 400:
          message = "Incorrect authentication data";
          break;

        case 504:
          message = "Unable to access program";
          break;

        case 404:
          message = "Not found";
          break;

        case 500:
          message = "Server error";
          break;

        default:
          message = error;
          break;
      }

      if (message.length > 0) {
        notify({
          type: "error",
          text: message,
        });
      }
    });

  return response;
}
