import store from "@/store/index";
import { HOST } from "@/entities/api/dbHost";
import { RestGateway } from "@/services/api/RestGateway";

export const RestService = {
  login: async function (url, params) {
    const response = await RestGateway({
      method: "post",
      url: HOST() + url,
      headers: { "Content-Type": "application/json" },
      data: params,
    });
    return response;
  },

  post: async function (url, params) {
    const response = await RestGateway({
      method: "post",
      url: HOST() + url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${store.state.token}`,
      },
      data: params,
    });
    return response;
  },

  get: async function (url, params = {}) {
    const response = await RestGateway({
      method: "get",
      url: HOST() + url,
      params: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${store.state.token}`,
      },
    });
    return response;
  },
};
