<template>
  <div class="app__container">
    <v-preloader v-if="preloader" />
    <div class="menu-container">
      <div class="menu" v-if="!isMenuActive" @click="toggleMenu">
        <svg
          width="30px"
          height="30px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z"
            fill="#ffffff"
          />
        </svg>
      </div>
    </div>
    <div class="list-container" v-if="isMenuActive">
      <div class="list__cross" @click="toggleMenu">
        <svg
          fill="#ffffff"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="30px"
          height="30px"
          viewBox="0 0 220.496 220.496"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                d="M0.003,192.658c0,7.434,2.897,14.424,8.154,19.684c5.257,5.261,12.25,8.154,19.684,8.154
			c7.44,0,14.429-2.894,19.674-8.154l62.732-62.731l62.717,62.72c5.255,5.26,12.244,8.154,19.684,8.154
			c7.44,0,14.436-2.895,19.678-8.154c5.255-5.26,8.155-12.25,8.155-19.684s-2.9-14.424-8.155-19.684l-62.714-62.717l62.727-62.727
			c5.254-5.257,8.154-12.247,8.154-19.681c0-7.434-2.9-14.427-8.154-19.684C207.084,2.897,200.095,0,192.655,0
			c-7.435,0-14.424,2.897-19.678,8.155l-62.724,62.732L47.522,8.155C42.271,2.897,35.281,0,27.847,0
			c-7.44,0-14.433,2.897-19.684,8.155c-5.257,5.257-8.155,12.25-8.155,19.684c0,7.434,2.897,14.423,8.155,19.681l62.732,62.727
			L8.157,172.974C2.9,178.234,0.003,185.224,0.003,192.658z M16.858,181.681l67.08-67.086c1.156-1.152,1.801-2.714,1.801-4.344
			c0-1.631-0.646-3.198-1.801-4.348l-67.08-67.08c-2.939-2.936-4.552-6.836-4.552-10.985c0-4.152,1.618-8.056,4.552-10.989
			c2.939-2.933,6.831-4.552,10.989-4.552c4.149,0,8.055,1.619,10.98,4.552l67.079,67.077c2.306,2.306,6.39,2.306,8.695,0
			l67.07-67.077c5.867-5.873,16.104-5.861,21.972,0c2.937,2.939,4.552,6.836,4.552,10.989c0,4.149-1.615,8.056-4.552,10.985
			l-67.073,67.074c-2.402,2.404-2.402,6.29,0,8.697l67.062,67.056c2.936,2.937,4.545,6.84,4.545,10.989s-1.609,8.053-4.551,10.988
			c-5.85,5.867-16.094,5.873-21.966,0l-67.059-67.056c-2.405-2.407-6.29-2.407-8.695,0l-67.085,67.08
			c-5.846,5.866-16.09,5.872-21.962,0c-2.939-2.937-4.552-6.84-4.552-10.989C12.307,188.515,13.919,184.612,16.858,181.681z"
              />
            </g>
          </g>
        </svg>
      </div>
      <ul class="list">
        <li class="list__item" @click="openFaq">FAQ</li>
        <li class="list__item" @click="openVerification">VERIFICATION</li>
        <li class="list__item" @click="openSupport">SUPPORT</li>
      </ul>
    </div>
    <div id="coomeet_container"></div>
  </div>
</template>

<script>
import VPreloader from "../../common/VPreloader.vue";

export default {
  components: { VPreloader },
  data() {
    return {
      preloader: false,
      isMenuActive: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    openFaq() {
      window.open(
        "https://docs.google.com/document/d/1UdqkecAVu2469qdHUxaek7WTx9yEoiZ7KJEEVg1JVH8/preview"
      );
    },
    openVerification() {
      window.open(
        "https://docs.google.com/document/d/1wf8Oc1ggjDKBep5G1UMAJGcf37mwQhcxuUWuni3bILE/preview"
      );
    },
    openSupport() {
      window.open("https://t.me/Purple_Support_Ua");
    },
  },
  beforeCreate() {
    var coomeet = document.createElement("script");
    coomeet.type = "text/javascript";
    coomeet.async = true;
    coomeet.src = "https://iframe.coomeet.me/js/code.js";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(coomeet, s);
  },
  created() {
    localStorage.setItem("coomeet-user-gender", "female");
    localStorage.setItem("coomeet-locale", "ru");
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        setTimeout(() => {
          this.preloader = false;
        }, 3000);
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.app__container {
  position: relative;
  z-index: 100;
  height: 100%;
  width: 100%;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
}

.menu-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  display: flex;
  justify-content: center;
}

.list-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.list {
  list-style: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  &__item {
    display: flex;
    padding: 10px;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    cursor: pointer;

    &:hover {
      color: #fdab42;
    }
  }
  &__cross {
    color: #fff;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &:hover {
      #Capa_1 {
        fill: #fdab42;
      }
    }
  }
}
.menu {
  width: 60px;
  height: 30px;
  background-color: #fdab42;
  // border: 1px solid #0f0f0f;
  // border-top: 0;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: #f3941a;
  }
}

// .app__footer__settings {
//   width: 100%;
//   height: 5px;
//   background-color: transparent;
//   position: absolute;
//   bottom: 0;

//   display: flex;
//   justify-content: center;

//   &__circle {
//     position: relative;
//     width: 120px;
//     height: 100px;
//     // border-radius: 50%;
//     bottom: 40px;
//     background-color: transparent;
//     box-sizing: border-box;
//   }
// }

#coomeet_container {
  height: 100%;
  width: 100%;
}
</style>
