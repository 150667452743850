import { createStore } from "vuex";
import { RestService } from "@/services/api/RestService";
import { endpoints } from "@/entities/api/endpoints";

export default createStore({
  state: {},
  getters: {},
  mutations: {
    setToken(state, data) {
      state.token = data;
    },
    setUsersList(state, data) {
      state.usersList = data;
    },
  },
  actions: {
    async login(state, credentails) {
      const data = await RestService.login(endpoints.login, credentails);
      state.commit("setToken", data.token);
    },
    async getUsersList(state) {
      const data = await RestService.get(endpoints.getUsersList);
      state.commit("setUsersList", data);
    },
  },
  modules: {},
});
